import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

export const getVehicleOutwardList = createAsyncThunk(
    "/getVehicleOutwardList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    // params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/production/vehicleoutward/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getVehicleOutwardByID = createAsyncThunk(
    "/getVehicleOutwardByID",
    async (payload) => {
        try {
            const response = await getList("/production/vehicleoutward/" + payload + "/");
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);

export const VehicleOutwardAdd = createAsyncThunk(
    "/VehicleOutwardAdd",
    async (payload) => {

        try {
            const response = await postAdd("/production/vehicleoutward/", payload);
            if (response.status === 201) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Vehicle Outward Added Successfully.!
                            </p>
                        </div>
                    ),
                });
                return { response, modal: false };
            }
            else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to add Vehicle Outward. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                        {error.response.data.error[0]}
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const VehicleOutwardEdit = createAsyncThunk(
    "/VehicleOutwardEdit",
    async (payload) => {
        try {
            const { obj} = payload;
            const response = await postEdit("/production/vehicleoutward/" + payload.id + "/", obj);
            if (response.status === 200) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Vehicle Outward Updated Successfully.!
                            </p>
                        </div>
                    ),
                });
                return { response, active: false };
            } else {
                throw new Error("Sorry! Unable to update Vehicle Outward. Please try again!")
            }
        } catch (err) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {err.message}
                        </p>
                    </div>
                ),
            });
            throw err;
        }
    }
);
export const VehicleOutwardDelete = createAsyncThunk(
    "/VehicleOutwardDelete",
    async (payload, { dispatch }) => {
        try {
            const response = await postDelete("/production/vehicleoutward/" + payload.id +"/");
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Vehicle Outward has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Vehicle Outward has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const vehicleOutwardAttachments = createAsyncThunk(
	"/vehicleOutwardAttachments",
	async (payload) => {
		const { file, from, onSuccess, onFailure } = payload;
		try {
			const response = await postFormData(`/production/attachment/`, {
				file: file,
			});
			onSuccess(response.data);
			return { from, response: response.data };
		} catch (err) {
			onFailure(err.message);
			throw err;
		}
	}
);
export const getStockTransferForVehicleOutward = createAsyncThunk(
    "/getStockTransferForVehicleOutward",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/stock/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
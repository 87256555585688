import { createAsyncThunk } from "@reduxjs/toolkit";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import Swal from "sweetalert2";

import { getList, getParamsList, postAdd, postDownloadFile } from "../../helpers/Helper";
import { downloadExportData, downloadStockExportData, stockexportdownloadfile } from "../settings/ImportExport/importExportAction";


export const getReportList = createAsyncThunk(
    "/getReportList",
    async (payload, { dispatch }) => {
        var params = payload.ParamsData;
        Swal.fire({
            text: "Loading please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false
        });
        try {
            if (payload.objData.file_format == 5) {
                const response = await postAdd(
                    `/reports/generic_export/`,
                    payload.objData,
                    params,
                    // signal,
                );
                if (response) {
                    Swal.close();
                    return { response: response.data };
                } else {
                    throw new Error(response);
                }
            } else {
                dispatch(downloadExportData(payload.objData))
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Your Report Generate Successfully.!
                            </p>
                        </div>
                    ),
                });
            }
        } catch (error) {
            console.log(error)
            if (error?.name != "CanceledError") {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),

                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Getting from server side issue!
                            </p>
                        </div>
                    ),
                });
            }
            return error.message;
        }
    }
);

export const getStockReportList = createAsyncThunk(
    "/getStockReportList",
    async (payload, { dispatch }) => {
        // var params = payload.ParamsData;
        console.log("payload..",payload.ParamsData)
        var data = payload.ParamsData;
        var params = {};

        for (const k in data) {
            if (data.hasOwnProperty(k)) {
                let value = data[k];
        
                // Skip keys with null, undefined, or empty string values
                if (value !== null && value !== undefined && value !== "") {
                    if (k === "currentSort" || k === "sortOrder") {
                        params.ordering = data.sortOrder + data.currentSort;
                    } else {
                        params[k] = value;
                    }
                }
            }
        }
        Swal.fire({
            text: "Loading please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false
        });
        try {
            if (payload.objData.file_format == 5) {
                const response = await getParamsList(
                    `/transfers/stockledger/report`,
                    // payload.objData,
                    params,
                    // signal,
                );
                if (response) {
                    Swal.close();
                    return { response: response };
                } else {
                    throw new Error(response);
                }
            } else {
                dispatch(downloadStockExportData(payload.objData))
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Your Report Generate Successfully.!
                            </p>
                        </div>
                    ),
                });
            }
        } catch (error) {
            console.log(error)
            if (error?.name != "CanceledError") {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),

                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Getting from server side issue!
                            </p>
                        </div>
                    ),
                });
            }
            return error.message;
        }
    }
);

export const startPostScheduleReport = createAsyncThunk(
    "/startPostScheduleReport",
    async (payload) => {
        var params = payload.ParamsData;
        try {

            const response = await postAdd(
                `/reports/scheduledemail/`,
                payload.obj,
                params,
            );
            if (response) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Schedule Added Successfully
                            </p>
                        </div>
                    ),
                });
                setTimeout(() => {
                    Modal.destroyAll();
                    // payload.form.submit();
                }, 2000);
                payload.schedule_form.setFieldsValue({
                    email: "",
                    startdate: "",
                    time: "",
                    frequency: null,
                    repeatdays: null,
                    reportname: "",
                });
                payload.setState({
                    email: "",
                    startdate: "",
                    time: "",
                    frequency: null,
                    repeatdays: null,
                    reportname: "",
                });
                return { response: response.data };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Swal.close();
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);